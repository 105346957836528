<template>
  <div class="pageCentent">
    <a-form-model size="small" layout="inline" @keyup.native.enter="search()">
      <a-form-model-item label="反馈用户">
        <a-input
          placeholder="反馈用户"
          v-model="ajaxparams['user_name_no']"
          allowClear
        />
      </a-form-model-item>
      <a-form-model-item label="问题类型">
        <a-select
          style="width: 200px"
          v-model="ajaxparams['type']"
          placeholder="请选择"
          allowClear
        >
          <a-select-option :value="1">建议</a-select-option>
          <a-select-option :value="2">市场问题反馈</a-select-option>
          <a-select-option :value="3">其他问题反馈</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="涉及用户">
        <a-input
          placeholder="请输入涉及用户"
          v-model="ajaxparams['refer_user']"
          allowClear
        />
      </a-form-model-item>
      <a-form-model-item label="反馈状态">
        <a-select
          style="width: 100px"
          v-model="ajaxparams['status']"
          placeholder="请选择"
          allowClear
        >
          <a-select-option :value="0">未处理</a-select-option>
          <a-select-option :value="1">处理中</a-select-option>
          <a-select-option :value="2">查收</a-select-option>
          <a-select-option :value="3">已完结</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="日期">
        <a-range-picker
          v-model="ajaxparams['create_at_between']"
          format="YYYY-MM-DD"
          valueFormat="YYYY-MM-DD"
          value="null"
        />
      </a-form-model-item>
      <a-form-model-item>
        <a-button icon="search" @click="$refs.myTable.search()" type="primary">
          搜索</a-button
        >
      </a-form-model-item>
      <a-form-model-item>
        <a-button :loading="buttonLoading" @click="doExport" type="primary">
          导出
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <MyTable
      class="mt10"
      :isSelection="false"
      :apiUrl="apiUrl"
      :params="ajaxparams"
      ref="myTable"
      :columns="columns"
    >
      <a-space slot="type" slot-scope="item, row, index">
        <span v-if="row.type == 1">建议</span>
        <span v-else-if="row.type == 2">市场问题反馈</span>
        <span v-else-if="row.type == 3">其他问题反馈</span>
      </a-space>
      <a-space slot="user" v-if="row.user" slot-scope="item, row, index">
        <span>{{ row.user.name }}</span>
      </a-space>
      <a-space slot="status" slot-scope="item, row, index">
        <a-tag v-if="row.status == 0">未处理</a-tag>
        <a-tag color="#2db7f5" v-else-if="row.status == 1">处理中</a-tag>
        <a-tag color="#108ee9" v-else-if="row.status == 2">查收</a-tag>
        <a-tag color="#87d068" v-else-if="row.status == 3">已完结</a-tag>
      </a-space>
      <a-space slot="imgs" slot-scope="item, row, index" style="display: flex">
        <imgUpload
          :list="row.imgs"
          :disabled="true"
          :uploadType="img"
        ></imgUpload>
      </a-space>
      <a-space slot="button" slot-scope="item, row, index">
        <a-button size="small" @click="showDetail(row)"> 查看详情 </a-button>
        <a-button
          size="small"
          @click="handleOver(row)"
          type="primary"
          v-if="row.status == 0"
        >
          查收
        </a-button>
        <a-button
          size="small"
          @click="showChangeRemarkDialog(row)"
          type="primary"
          v-if="row.status == 1 && row.type == 2"
        >
          审核
        </a-button>
      </a-space>
    </MyTable>
    <a-modal
      :title="'审核'"
      :visible="showRemarkDialog"
      @ok="changeRemark"
      @cancel="showRemarkDialog = false"
    >
      <a-form-model-item label="状态" prop="is_take">
        <a-select
          style="width: 100px"
          v-model="status"
          placeholder="请选择"
          allowClear
        >
          <!-- <a-select-option :value="1">处理中</a-select-option> -->
          <!-- <a-select-option :value="2">查收</a-select-option> -->
          <a-select-option :value="3">已完结</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="备注">
        <a-textarea
          placeholder="请输入备注信息"
          v-model="remark"
          :auto-size="{ minRows: 3, maxRows: 8 }"
        >
        </a-textarea>
      </a-form-model-item>
    </a-modal>

    <a-modal
      :title="'查看详情'"
      @cancel="showDetailModal = false"
      :visible="showDetailModal"
      :footer="null"
      :maskClosable="true"
    >
      <a-form-model
        :label-col="{ span: 5 }"
        ref="formModel"
        size="small"
        :model="form"
      >
        <a-form-model-item label="类型">
          <div v-if="form.type == 1">建议</div>
          <div v-else-if="form.type == 2">市场问题反馈</div>
          <div v-else-if="form.type == 3">其他问题反馈</div>
        </a-form-model-item>
        <a-form-model-item label="问题描述">
          <div>{{ form.content }}</div>
        </a-form-model-item>
        <a-form-model-item label="凭证" style="display: flex">
          <div>
            <imgUpload
              :list="form.imgs"
              :disabled="true"
              :uploadType="img"
            ></imgUpload>
          </div>
        </a-form-model-item>
        <a-form-model-item label="反馈用户" v-if="form.user">
          <div>{{ form.user.name }}</div>
        </a-form-model-item>
        <template v-if="form.type == 2">
          <a-form-model-item label="地址">
            <div>{{ form.place }}</div>
          </a-form-model-item>
          <a-form-model-item label="涉及用户">
            <div>{{ form.refer_user }}</div>
          </a-form-model-item>
          <a-form-model-item label="产品">
            <div>{{ form.product }}</div>
          </a-form-model-item>
          <a-form-model-item label="人数">
            <div>{{ form.num }}</div>
          </a-form-model-item>
          <a-form-model-item label="价格">
            <div>{{ form.price }}</div>
          </a-form-model-item>
          <a-form-model-item label="其他说明">
            <div>{{ form.other }}</div>
          </a-form-model-item>
        </template>
        <a-form-model-item label="状态">
          <div v-if="form.status == 0">未处理</div>
          <div v-else-if="form.status == 1">处理中</div>
          <div v-else-if="form.status == 2">查收</div>
          <div v-else-if="form.status == 3">已完结</div>
        </a-form-model-item>
        <a-form-model-item label="日期">
          <div>{{ form.create_at }}</div>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import MyTable from "@/components/myTable.vue";
import {
  feedback_index,
  feedback_status,
  feedback_export,
} from "@/api/manager";
import imgUpload from "@/components/myUpload.vue";

export default {
  components: { MyTable, imgUpload },
  data() {
    return {
      apiUrl: feedback_index,
      ajaxparams: {},
      columns: [
        {
          title: "类型",
          width: 120,
          ellipsis: true,
          dataIndex: "type",
          key: "type",
          scopedSlots: { customRender: "type" },
        },
        {
          title: "问题描述",
          width: 300,
          dataIndex: "content",
          key: "content",
        },

        {
          title: "反馈用户",
          width: 120,
          dataIndex: "user",
          scopedSlots: { customRender: "user" },
        },
        {
          title: "涉及用户",
          dataIndex: "refer_user",
          key: "refer_user",
        },
        {
          title: "状态",
          dataIndex: "status",
          key: "status",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "备注",
          dataIndex: "remark",
          ellipsis: true,
          key: "remark",
        },
        {
          title: "日期",
          dataIndex: "create_at",
          ellipsis: true,
          key: "create_at",
        },
        {
          title: "操作",
          key: "operation",
          width: 200,
          scopedSlots: { customRender: "button" },
        },
      ],
      showRemarkDialog: false,
      currentId: 0,
      buttonLoading: false,
      status: "请选择",
      remark: "",
      showDetailModal: false,
      form: {},
    };
  },
  mounted(data) {},
  methods: {
    search() {
      this.$refs.myTable.search();
    },
    doExport() {
      this.buttonLoading = true;
      feedback_export(this.ajaxparams).then(
        (res) => {
          this.buttonLoading = false;
          this.$message.success("添加导出队列成功");
          this.$db.set("is_export", 1);
        },
        (err) => {
          this.buttonLoading = false;
          this.$message.warning("导出失败");
        }
      );
    },
    changeRemark() {
      const _this = this;
      if (_this.remark == "") {
        _this.$message.warning("请输入备注");
        return;
      }
      if (_this.status == "请选择") {
        _this.$message.warning("请选择状态");
        return;
      }
      feedback_status({
        id: this.currentId,
        status: this.status,
        remark: this.remark,
      }).then((res) => {
        _this.$message.success("操作成功");
        setTimeout(() => {
          _this.showRemarkDialog = false;
          _this.$refs.myTable.getList();
          _this.status = "请选择";
          _this.remark = "";
        }, 1500);
      });
    },
    handleOver(row) {
      let status = "";
      if (row.type == 1 || row.type == 3) {
        status = 2;
      } else if (row.type == 2) {
        status = 1;
      }
      const _this = this;
      feedback_status({
        id: row.id,
        status,
      }).then((res) => {
        _this.$message.success("操作成功");
        setTimeout(() => {
          _this.$refs.myTable.getList();
        }, 1000);
      });
    },
    //修改备注
    showChangeRemarkDialog(row) {
      this.showRemarkDialog = true;
      this.currentId = row.id;
    },
    showDetail(row) {
      this.showDetailModal = true;
      this.form = row;
    },
  },
};
</script>
	
<style scoped>
.w150 {
  width: 150px;
}
.w100 {
  width: 100px;
}
</style>