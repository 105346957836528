<!-- 账号管理 -->
<style scoped>
.mt20 {
  margin: 5px 20px;
}
.mt5 {
  margin: 5px;
}
.mb20 {
  margin-bottom: 20px;
  margin-right: 10px;
  padding: 3px;
}
.share-img {
  display: block;
  width: 200px;
  min-height: 200px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #efefef;
  padding: 10px 0;
}

.share-img img {
  width: 100%;
}

.share-img_bottom {
  display: block;
  padding: 5px 10px 0 10px;
}
.share-img_bottom strong {
  display: block;
  line-height: 160%;
}
.share-img_bottom strong.og {
  color: #fb710a;
}
</style>
<template>
  <div>
    <div style="background-color: #fff;padding: 16px;margin-bottom: 20px;">
      <!-- 搜索条件 -->
      <a-form-model
        size="small"
        layout="inline"
        :model="ajaxparams"
        @keyup.native.enter="$refs.myTable.search()"
      >
        <a-form-model-item label="商品名称">
          <a-input placeholder="请输入商品名称" v-model="ajaxparams['goods_name_like']" />
        </a-form-model-item>
        <a-form-model-item label="是否推荐">
          <a-select style="width:150px" v-model="ajaxparams.is_recommend" placeholder="请选择推荐">
            <a-select-option :value="1">新品推荐</a-select-option>
            <a-select-option :value="2">年度好物</a-select-option>
            <a-select-option :value="3">大家都在买</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="是否热榜">
          <a-select style="width:150px" v-model="ajaxparams.is_best_sell" placeholder="请选择是否热榜">
            <a-select-option :value="1">是</a-select-option>
            <a-select-option :value="0">否</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="是否预售">
          <a-select style="width:150px" v-model="ajaxparams.is_presale" placeholder="请选择">
            <a-select-option :value="1">是</a-select-option>
            <a-select-option :value="0">否</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="库存">
          <a-space>
            <a-input placeholder="请输入最小库存" v-model="ajaxparams['total_stock_min']" />
            <a-input placeholder="请输入最大库存" v-model="ajaxparams['total_stock_max']" />
          </a-space>
        </a-form-model-item>
        <a-form-model-item label="价格">
          <a-space>
            <a-input placeholder="请输入最小价格" v-model="ajaxparams['goods_price_min']" />
            <a-input placeholder="请输入最大价格" v-model="ajaxparams['goods_price_max']" />
          </a-space>
        </a-form-model-item>
        <a-form-model-item label="销量">
          <a-space>
            <a-input placeholder="请输入最小销量" v-model="ajaxparams['sales_num_min']" />
            <a-input placeholder="请输入最大销量" v-model="ajaxparams['sales_num_max']" />
          </a-space>
        </a-form-model-item>
        <a-form-model-item label="上架状态">
          <a-select
            style="width:150px"
            allowClear
            v-model="ajaxparams.is_shelves"
            placeholder="请选择上架状态"
          >
            <a-select-option :value="1">上架</a-select-option>
            <a-select-option :value="0">下架</a-select-option>
          </a-select>
        </a-form-model-item>
        
        <a-form-model-item label="分类">
          <a-select
            style="width:150px"
            allowClear
            v-model:value="ajaxparams.classify_first_id"
            :options="classifyData.map(cls => ({value:cls.id,title:cls.name}))"
            placeholder="请选择分类"
          >
          </a-select>

          <a-select
            style="width:150px"
            allowClear
            v-model:value="ajaxparams.classify_second_id"
            :options="secondClassify.map(cls => ({value:cls.id,title:cls.name}))"
            placeholder="请选择二级分类"
          >
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="可售区域">
          <a-select
            style="width:200px"
            allowClear
            v-model="ajaxparams.province_code_no"
            placeholder="请选择省份（不选为全国）"
          >
            <a-select-option
              :value="item.code"
              v-for="(item, index) in regionData"
              :key="index"
            >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
    
        <a-form-model-item>
          <a-button icon="search" @click="$refs.myTable.search()" type="primary">搜索</a-button>
        </a-form-model-item>
        <a-form-model-item >
          <a-button  @click="doExport" type="primary"> 导出 </a-button>
        </a-form-model-item>
    </a-form-model>
      </a-form-model>
      <!-- 按钮 -->
      <a-space>
        <a-button type="primary" @click="dialogClick('add')">添加</a-button>
        <a-button type="link" @click="dialogClick('lack')">缺货商品({{ outOfStockData.count }})</a-button>
      </a-space>
      <!-- 表格 -->
      <MyTable
        :isSelection="false"
        class="mt10"
        :apiUrl="goods_index"
        :params="ajaxparams"
        ref="myTable"
        :columns="columns"
      >
        <a-space slot="button" slot-scope="item,row,index" v-if="!row.is_shelves">
          <div>
            
              <a-button class="mt5" size="small" @click="dialogFobidenCity(row.id)">屏蔽城市</a-button>
              <a-button class="mt5" size="small" @click="dialogFobidenUser(row.id)">屏蔽用户</a-button>
              <a-button class="mt5" size="small" @click="dialogGoodsWriteUser(row.id)">白名单</a-button>
              <a-button class="mt5" size="small" type="primary" @click="dialogClick('edit', row)">编辑</a-button>
              <a-button class="mt5" size="small" type="primary" @click="sBestSell(row)">排行榜</a-button>
              <a-popconfirm title="确定是否删除?" ok-text="确定" cancel-text="取消" @confirm="delConfirm(row)">
                <a-button class="mt5" size="small" type="danger">删除</a-button>
              </a-popconfirm>
          </div>
          
        </a-space>
        <a-space slot="sort" slot-scope="item,row,index">
          <editable-cell
            :ref="'edittableCell' + row.id"
            :text="row.sort"
            @change="onCellChange($event, row, index)"
          />
        </a-space>
        <a-space slot="cover_img" slot-scope="item,row,index" @click="showImgPopup(item)" >
          <img
            v-if="row.cover_img"
            style="width:30px;heigth:30px"
            :src="IMGHOST + '/' + row.cover_img"
            
          />
          <span v-else>-</span>
        </a-space>
        <a-space slot="goods_price" slot-scope="item,row,index">
          <span>{{ row.goods_price / 100 }}</span>
        </a-space>
        <a-space slot='goods_type' slot-scope="item,row,index">
				<span>{{goodsTypeData[item]}}</span>
        </a-space>
<a-space slot='is_presale' slot-scope="item,row,index">
				  <span>{{row.is_presale?'是':'否'}}</span>
        </a-space>

        <a-space slot="is_shelves" slot-scope="item,row,index">
          <a-switch
            @click="switchClick(row, index)"
            :disabled="isDisabledSwitch"
            checked-children="上架"
            un-checked-children="下架"
            :defaultChecked="row.is_shelves == 1 ? true : false"
          />
        </a-space>
        <!-- 核心产品 -->
        <a-space slot="is_important" slot-scope="item,row,index">
          <a-switch
            @click="switchImportant(row, index)"
            checked-children="核心"
            un-checked-children="普通"
            :defaultChecked="row.is_important == 1 ? true : false"
          />
        </a-space>
      </MyTable>
    </div>
    
    <!-- 缺货商品 -->
    <a-modal
      :maskClosable="false"
      v-if="dialogType == 'lack'"
      :footer="null"
      width="900px"
      @cancel="handleCancel"
      v-model="dialogVisible"
      title="缺货商品"
    >
      <a-table
        :rowKey="'id'"
        :pagination="false"
        class="mt10"
        :dataSource="outOfStockData.list"
        :columns="stockColumns"
      >
        <a-space slot="img" slot-scope="item,row,index" >
          <img :width="30" :src="IMGHOST + '/' + item" />
        </a-space>
        <a-space slot="stock" slot-scope="item,row,index">
          <editable-cell
            :ref="'stock' + row.id"
            :text="row.stock ? row.stock : '0'"
            @change="stockChange($event, row, index)"
          />
        </a-space>
        <a-space slot="price" slot-scope="item,row,index">{{ row.price / 100 }}</a-space>
        <a-space slot="spec_desc" slot-scope="item,row,index">{{ row.desc }}</a-space>
      </a-table>
    </a-modal>
    <!-- 添加编辑商品 -->
    <a-modal
      :maskClosable="false"
      v-else
      v-model="dialogVisible"
      width="82%"
      :title="dialogType == 'add' ? '添加商品' : '编辑商品'"
      :confirm-loading="submitLoading"
      @ok="onSubmit"
      @cancel="handleCancel"
    >
        <a-form-model
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 18 }"
          :rules="rules"
          ref="formModel"
          size="small"
          :model="form"
        >
          <a-form-model-item label="商品类型" placeholder="请选择商品分类">
            <a-select
              v-model="form.goods_type"
              @change="getGoodsTypeSpec"
              :defalutValue="1"
              :disabled="dialogType != 'add'"
            >
              <a-select-option :value="k" v-for="(v,k) of goodsTypeData" :key="k" > {{v}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="商品名称" prop="goods_name">
            <a-input placeholder="请输入商品名称" v-model="form.goods_name" />
          </a-form-model-item>
          <a-form-model-item label="商品标签" >
            <a-input-group compact>
                <a-input placeholder="请输入标签" v-model="tagName" style="width:20%;"/>
                <a-input placeholder="请输入字体色 16进制色值" v-model="tagColor" style="width:20%;"/>
                <a-input-search placeholder="请输入背景色 16进制色值" v-model="tagBgColor" enter-button="添加" style="width:40%;" @search="addTag"/></a-input-search>
            </a-input-group>
            <a-tag closable @close="delTag(index)" :style="{color:tag.color,backgroundColor:tag.bgColor}" v-for="(tag,index) in form.tags" :key="tag.name">{{tag.name}}</a-tag>
          </a-form-model-item>
          <a-form-model-item label="商品描述" prop="brief">
            <a-textarea placeholder="请输入商品描述" v-model="form.brief" />
          </a-form-model-item>
          
          <a-row>
            <a-col :span="8">
              <a-form-model-item
                :label-col="{ span: 9 }"
                :wrapper-col="{ span: 12 }"
                label="封面图" prop="cover_img">
                <div class="fs12 color666">建议上传尺寸：750*750</div>
                <imgUpload
                  v-if="dialogVisible"
                  @remove="removeUpload"
                  @result="resultUpload"
                  :list="form.cover_img ? [form.cover_img] : []"
                  :maxLength="1"
                  :type="'cover'"
                ></imgUpload>
              </a-form-model-item>
            </a-col>
            <a-col :span="16">
              <a-form-model-item label="分享图片" prop="share_img">
                
                <a-button @click="saveImg">{{ form.share_img ? '重新' : '' }}生成分享图片</a-button>
                <div ref="shareImg" class="share-img" v-show="showShareImg">
                  <img
                    v-if="form.cover_img"
                    :src="IMGHOST + '/' + form.cover_img"
                  />
                  <div class="share-img_bottom">
                    <strong>{{ form.goods_name }}</strong>
                    <!-- <strong class="og">{{ goods_price }}</strong> -->
                  </div>
                </div>
                <a-input v-model="form.share_img" hidden/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-form-model-item label="轮播图" prop="banner_imgs">
            <div class="fs12 color666">建议上传尺寸：750*750</div>
            <imgUpload
              v-if="dialogVisible"
              @remove="removeUpload"
              @result="resultUpload"
              :list="form.banner_imgs ? form.banner_imgs : []"
              :maxLength="10"
              :type="'banner'"
            ></imgUpload>
          </a-form-model-item>
          <a-row>
            <a-col :span="8">
              <a-form-model-item
                label="视频"
                prop="video"
                :label-col="{ span: 9 }"
                :wrapper-col="{ span: 12 }"
              >
                <imgUpload
                  v-if="dialogVisible"
                  @remove="removeUpload"
                  @result="resultUpload"
                  :uploadType="'video'"
                  :list="form.video ? [form.video] : []"
                  :maxLength="1"
                  :type="'video'"
                ></imgUpload>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item
                label="视频封面"
                prop="video"
                :label-col="{ span: 9 }"
                :wrapper-col="{ span: 12 }"
              >
                <imgUpload
                  v-if="dialogVisible"
                  @remove="removeUpload"
                  @result="resultUpload"
                  :uploadType="'img'"
                  :list="form.video_img ? [form.video_img] : []"
                  :maxLength="1"
                  :type="'video_img'"
                ></imgUpload>
                <div class="fs12 color666">建议上传尺寸：750*750。(有设置用设置的封面图，没设置用视频截图)</div>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-form-model-item label="排序" prop="sort">
            <a-input placeholder="请输入排序" v-model="form.sort" />
          </a-form-model-item>
          <a-form-model-item label="可售区域" prop="province_code">
            <a-select
              mode="multiple"
              allowClear
              v-model="form.province_code"
              placeholder="请选择省份（不选为全国）"
            >
              <a-select-option
                :value="item.code"
                v-for="(item, index) in regionData"
                :key="index"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item label="是否禁售" prop="area_limit" >
            <a-select v-model="form.area_limit"   >
              <a-select-option :value="0">否</a-select-option>
              <a-select-option :value="1">是</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="是否预售" prop="is_presale" >
            <a-select v-model="form.is_presale"   >
              <a-select-option :value="0">否</a-select-option>
              <a-select-option :value="1">是</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item v-if="form.is_presale" label="预售日期" prop="presale_date" >
            <a-date-picker :format="'YYYY-MM-DD hh:mm:ss'" v-model="form.presale_date" show-time placeholder="请选择预售日期" />
          </a-form-model-item>
          
          <a-form-model-item label="推荐类别" prop="is_recommend">
            <a-select v-model="form.is_recommend" placeholder="请选择推荐类别">
              <a-select-option :value="0">不推荐</a-select-option>
              <a-select-option :value="1">新品推荐</a-select-option>
              <a-select-option :value="2">年度好物</a-select-option>
              <a-select-option :value="3">大家都在买</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="品牌" prop="brand_id" >
            <a-select v-model="form.brand_id" placeholder="请选择" >
              <a-select-option
                :value="item.id"
                v-for="(item, index) in brandData"
                :key="index"
              >{{ item.brand_name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="分类" prop="classify_id">
            <a-cascader
              v-model="form.classify_id"
              :field-names="{ label: 'name', value: 'id', children: 'children' }"
              :options="classifyData"
              placeholder="请选择分类"
              @change="changeClassify"
            />
          </a-form-model-item>
          <a-form-model-item label="商品特性" prop="charact_ids">
            <a-input v-model="form.charact_ids" type="hidden"  />
            <a-tag :color="form.charact_ids.indexOf(tag.id + ',') === -1 ? '' : 'green'" @click="toggleCharact(tag.id)" v-for="tag in charactTags" :key="tag.id">{{tag.name + tag.id}}</a-tag>
          </a-form-model-item>
          <a-form-model-item label="规格">
            <a-table
              :rowKey="'index'"
              :pagination="false"
              class="mt10"
              :dataSource="form.spec"
              :columns="specColumns"
              rowKey="id"
            >
              <a-space slot="button" slot-scope="item,row,index">
                <a-button size="small" @click="delSpec(index)" type="danger">删除</a-button>
              </a-space>
              <a-space slot="img" slot-scope="item,row,index">
                <imgUpload
                  @remove="removeUpload"
                  @result="resultUpload"
                  :list="row.img ? [row.img] : []"
                  :maxLength="1"
                  :type="'spec_' + index"
                ></imgUpload>
              </a-space>
              <a-space slot="stock" slot-scope="item,row,index">
                <a-input placeholder="请输入库存" v-model="row.stock" />
              </a-space>
              <a-space slot="num" slot-scope="item,row,index">
                <a-input placeholder="请输入数量" v-model="row.num" />
              </a-space>
              <a-space slot="price" slot-scope="item,row,index">
                <a-input placeholder="请输入规格价格" v-model="row.price" />
              </a-space>
              <a-space slot="origin_price" slot-scope="item,row,index">
                <a-input placeholder="请输入默认价格" v-model="row.origin_price" />
              </a-space>
              <a-space slot="spec_desc" slot-scope="item,row,index">
                <div>
                  <div
                    class="flex justify-start align-center"
                    :class="indexs != 0 ? 'mt10' : ''"
                    v-for="(items, indexs) in specData"
                    :key="indexs"
                  >
                    <span>{{ items.name }}：</span>
                    <a-select
                      style="width:170px"
                      showSearch
                      v-model="row.spec_desc[indexs]"
                      :key="indexs"
                      :placeholder="'请选择' + items.name"
                    >
                      <a-select-option
                        :value="items.name + ':' + t"
                        v-for="(t, i) in items.val"
                        :key="i"
                      >{{ t }}</a-select-option>
                    </a-select>
                  </div>
                </div>
              </a-space>
            </a-table>
            <a-button
              type="primary"
              v-if="form.is_single_spec != 1 || (form.is_single_spec == 1 && form.spec.length < 2)"
              @click="addSpec"
            >添加规格</a-button>
          </a-form-model-item>
          <a-form-model-item label="参数信息" prop="detail">
            <wangeditor v-if="dialogVisible" @change="parametersChange" :content="form.parameters"></wangeditor>
          </a-form-model-item>
          <!-- <a-form-model-item :label="items.title" prop="detail" v-for="(items, index) in parameters" :key="index">
            <a-select style="width:170px" v-model="items.sel" :placeholder="'请选择' + items.title">
              <a-select-option :value="t" v-for="(t, i) in items.pinzhongOption" :key="i">{{ t }}</a-select-option>
            </a-select>
          </a-form-model-item>-->
          <a-form-model-item label="详细信息" prop="detail">
            <wangeditor v-if="dialogVisible" @change="editorChange" :content="form.detail"></wangeditor>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
      <a-modal
        title="图片展示"
        :visible="showImgStatus"
        :footer="null"
        @cancel="showImgStatus=false"
        :width="250"
      >
        <img style="width:200px;"  :src="currentImg" />
    </a-modal>

    <!-- 屏蔽用户 -->
    <a-modal class="mb20" width="500px" @cancel="cancelForbidden" v-model="showForbidden" :title="userDialogType==='forbidden'?'屏蔽用户':'白名单用户'" :footer="null" >
        <a-splace>
          <a-input-search
            v-model="mobile"
            placeholder="请输入手机号"
            enter-button="确认"
            @search="openCheck"
            :loading="forbiddenLoading"
            class="mb20"
            width="200px"
          />
          <a-tag class="mb20" color="green" closable @close="delUser(item.id,index)" v-for="(item,index) in forbiddenData" :key="item.id"> {{item.name}} </a-tag>
        </a-splace>
    </a-modal>

    <!-- 检测 -->
    <a-modal class="mb20" width="500px" @ok="addUser" @cancel="checkModal=false" v-model="checkModal" title="检测信息" >
        <div style="">
          <div>
            用户屏蔽: {{checkItem.user_forbidden== 0 ? '否' : '是'}}
          </div>
           <div>
            品牌屏蔽: {{checkItem.brand_forbidden== 0 ? '否' : '是'}}
          </div>
           <div>
            地区屏蔽: {{checkItem.area_forbidden}}
          </div>
        </div>
        <a-tag class="mb20" color="green" closable @close="delForbiddenCity(item.id,index)" v-for="(item,index) in forbiddenCityData" :key="item.id"> {{item.area_name}} </a-tag>
        
    </a-modal>

    <!-- 屏蔽城市 -->
    <a-modal class="mb20" width="500px" v-model="showForbiddenCity" title="屏蔽城市" :footer="null" >
        <div style="display:flex;padding-bottom:10px;">
          <a-cascader 
            :options="regionTree"
            v-model="forbiddenCity"
            :field-names="{ label: 'name', value: 'code', children: 'children' }" :show-search="{ filterProvince }"
            placeholder="请选择地区" 
            style="width: 400px"
          />
          <a-button @click="addForbiddenCity" type="primary">确认</a-button>
        </div>
        <a-tag class="mb20" color="green" closable @close="delForbiddenCity(item.id,index)" v-for="(item,index) in forbiddenCityData" :key="item.id"> {{item.area_name}} </a-tag>
        
    </a-modal>


    <!-- 排行榜修改 -->
    <a-modal 
      :maskClosable="false" class="mb20" width="500px" 
      :confirm-loading="submitLoading"
      @ok="bestSellSubmit"
      @cancel="handleCancel"
      v-model="showBestSell" title="商品排行榜修改"  >
      <a-form-model
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
        ref="formBestSellModel"
        size="small"
        :model="form"
      >
        <a-form-model-item label="是否进入排行榜" placeholder="请选择是否进入排行榜">
          <a-select v-model="bestSellForm.is_best_sell"   >
            <a-select-option :value="0">否</a-select-option>
            <a-select-option :value="1">是</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="本期排名" v-if="bestSellForm.is_best_sell == 1">
          <a-select
            v-model="bestSellForm.current_rank"
            :defalutValue="1"
          >
            <a-select-option :value="k+1" v-for="(v,k) of 10" :key="k" > {{v}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="上期排名" v-if="bestSellForm.is_best_sell == 1">
          <a-select
            v-model="bestSellForm.last_rank"
            :defalutValue="1"
          >
            <a-select-option :value="k+1" v-for="(v,k) of 10" :key="k" > {{v}}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import EditableCell from "@/components/editableCell.vue";
import MyTable from "@/components/myTable.vue";
import wangeditor from "./components/wangeditor.vue";
import imgUpload from "@/components/myUpload.vue";
import {
  brand_index,
  goods_index,
  goods_add,
  goods_edit,
  goods_del,
  goods_changeShelves,
  goods_changeImportant,
  goods_changeSort,
  goods_detail,
  goods_outOfStock,
  goods_changeStock,
  goods_changeBestSell,
  UserGoodsWriteList_checkForbidden,
} from "@/api/goods";
import { classify_index } from "@/api/classify";
import { charact_index } from "@/api/charact";
import { user_search } from "@/api/users";
import { spec_index } from "@/api/spec";
import {
  forbidden_user_index,
  forbidden_user_add,
  forbidden_user_del,
  forbidden_city_index,
  forbidden_city_add,
  forbidden_city_del,
  UserGoodsWriteList_index,
  UserGoodsWriteList_add,
  UserGoodsWriteList_del,
} from "@/api/goods";
import { setTimeout } from "timers";

import debounce from "lodash/debounce";
import html2canvas from "html2canvas";
import { uploader } from "@/api/common";
import axios from "axios";
import { goods_spec_list } from "@/api/export";
export default {
  components: { MyTable, EditableCell, imgUpload, wangeditor },
  data() {
    return {
      showImgStatus: false,
      currentImg: false,
      goods_index: goods_index,
      IMGHOST: window.IMGHOST,
      ajaxparams: {}, //请求参数
      rowSelection: [],
      showForbidden: false,
      forbiddenLoading: false,
      forbiddenData: [],
      currentGoodsId: 0,
      mobile: "",
      /*
        列表header
      */
      columns: [
        {
          title: "操作",
          key: "operation",
          fixed: "left",
          // ellipsis: true,
          width: 150,
          scopedSlots: { customRender: "button" },
        },
        {
          title: "ID",
          // fixed: "left",
          ellipsis: true,
          width: 70,
          dataIndex: "id",
          key: "id",
        },
        {
          title: "商品名称",
          fixed: "left",
          ellipsis: true,
          width: 200,
          dataIndex: "goods_name",
          key: "goods_name",
        },
        {
          title: "商品封面",
          dataIndex: "cover_img",
          key: "cover_img",
          width: 100,
          scopedSlots: { customRender: "cover_img" },
        },
        { title: "商品描述", width: 250, dataIndex: "brief", key: "brief" },
        {
          title: "商品价格",
          ellipsis: true,
          width: 150,
          dataIndex: "goods_price",
          key: "goods_price",
          scopedSlots: { customRender: "goods_price" },
        },
        {
          title: "库存",
          ellipsis: true,
          dataIndex: "total_stock",
          key: "total_stock",
          width: 150,
          scopedSlots: { customRender: "total_stock" },
        },
        {
          title: "核心产品",
          ellipsis: true,
          dataIndex: "is_important",
          key: "is_important",
          width: 150,
          scopedSlots: { customRender: "is_important" },
        },
        {
          title: "商品类型",
          ellipsis: true,
          dataIndex: "goods_type",
          key: "goods_type",
          width: 150,
          scopedSlots: { customRender: "goods_type" },
        },

        {
          title: "排序",
          ellipsis: true,
          dataIndex: "sort",
          key: "sort",
          width: 150,
          scopedSlots: { customRender: "sort" },
        },
        // {
        // 	title: '可售区域', dataIndex: 'province_code', key: 'province_code', width: 200,
        // 	scopedSlots: { customRender: 'province_code' },
        // },
        {
          title: "上架状态",
          ellipsis: true,
          dataIndex: "is_shelves",
          key: "is_shelves",
          width: 150,
          scopedSlots: { customRender: "is_shelves" },
        },
        {
          title: "是否预售",
          ellipsis: true,
          dataIndex: "is_presale",
          key: "is_presale",
          width: 150,
          scopedSlots: { customRender: "is_presale" },
        },

        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "ctime",
          key: "ctime",
          width: 220,
        },
        {
          title: "更新时间",
          ellipsis: true,
          dataIndex: "utime",
          key: "utime",
          width: 220,
        },
      ],
      bestSellForm: {},
      stockColumns: [
        {
          title: "规格图片",
          dataIndex: "img",
          key: "img",
          scopedSlots: { customRender: "img" },
        },
        {
          title: "商品名称",
          dataIndex: "goods_name",
          key: "goods_name",
        },
        {
          title: "库存",
          dataIndex: "stock",
          key: "stock",
          scopedSlots: { customRender: "stock" },
        },
        {
          title: "商品价格",
          dataIndex: "price",
          key: "price",
          scopedSlots: { customRender: "price" },
        },
        {
          title: "规格",
          dataIndex: "spec_desc",
          key: "spec_desc",
          width: 250,
          scopedSlots: { customRender: "spec_desc" },
        },
        {
          title: "更新时间",
          dataIndex: "utime",
          key: "utime",
          width: 250,
          scopedSlots: { customRender: "utime" },
        },
      ],
      specColumns: [
        {
          title: "规格图片",
          dataIndex: "img",
          key: "img",
          scopedSlots: { customRender: "img" },
        },
        {
          title: "库存",
          dataIndex: "stock",
          key: "stock",
          scopedSlots: { customRender: "stock" },
        },
        // { title: '商品描述', dataIndex: 'brief', key: 'brief' ,scopedSlots: { customRender: 'brief' },},
        {
          title: "商品价格",
          dataIndex: "price",
          key: "price",
          scopedSlots: { customRender: "price" },
        },
        {
          title: "默认价格",
          dataIndex: "origin_price",
          key: "origin_price",
          scopedSlots: { customRender: "origin_price" },
        },
        {
          title: "数量",
          dataIndex: "num",
          key: "num",
          scopedSlots: { customRender: "num" },
        },
        {
          title: "规格",
          dataIndex: "spec_desc",
          key: "spec_desc",
          width: 250,
          scopedSlots: { customRender: "spec_desc" },
        },
        {
          title: "操作",
          key: "operation",
          width: 80,
          scopedSlots: { customRender: "button" },
        },
      ],
      isDisabledSwitch: false, //是否禁用Switch
      submitLoading: false,
      dialogType: "add",
      dialogVisible: false,
      form: {
        charact_ids: "",
        goods_name: "",
        tags: [],
        brief: "",
        banner_imgs: [],
        cover_img: "",
        parameters: "",
        is_recommend: 0,
        is_single_spec: 0,
        video: "",
        // "classify_id": "",
        attr_label_ids: "",
        sort: 100,
        point: 0,
        coin: 0,
        goods_type: 1,
        detail: "",
        spec: [],
        province_code: [],
        forbidden_area: [],
        area_limit: 1,
        is_presale: 0,
        charactTags: [],
      },
      classifyData: [],
      loading: false,
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        mobile: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        classify_id: [
          { required: true, message: "请选择分类", trigger: "change" },
        ],
      },
      specData: [],
      specDataAll: [],
      regionData: [],
      outOfStockData: {
        count: 0,
        list: [],
      },
      isEditStock: false,
      parameters: [
        {
          pinzhongOption: [
            "玉米",
            "水果玉米",
            "黄瓜",
            "冬瓜",
            "丝瓜",
            "豇豆",
            "辣椒",
            "青梗菜",
            "萝卜",
            "四季豆",
            "苦瓜",
          ],
          title: "植物品种",
          sel: "玉米",
        },
        {
          pinzhongOption: ["春季", "秋季", "四季"],
          title: "播种季节",
          sel: "春季",
        },
        {
          pinzhongOption: ["一年生", "两年生", "多年生"],
          title: "植物生长属性",
          sel: "一年生",
        },
        { pinzhongOption: ["否", "是"], title: "是否进口", sel: "是" },
      ],
      brandData: [],
      goodsTypeData: ["请选择", "种子", "农药", "苗", "肥料", "其他"],
      characts: [],
      tagName: "",
      tagColor: "#fff",
      tagBgColor: "#000",
      showForbiddenCity: false,
      forbiddenCityData: [],
      regionList: [],
      regionTree: [],
      forbiddenCity: null,
      showShareImg: false,
      userDialogType: "",
      showBestSell: false,
      checkModal: false,
      checkItem: {},
      userMobile: "",
    };
  },
  mounted() {
    let data = this.$db.get("regionList").data;
    let arr = [];
    data.forEach((item) => {
      if (item.pcode !== 0) {
        const idx = arr.findIndex((i) => i.pcode === item.pcode);
        if (idx === -1) {
          arr.push({
            ...item,
            id: item.pcode,
            depth: item.pcode,
            pcode: item.pcode,
            code: null,
            name: "全部",
          });
        }
      }
    });
    const newTree = [...arr, ...data];
    this.regionList = newTree;
    this.regionTree = this.$common.treeData(newTree, "pcode", "code", 0);

    let regionData = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].depth == 1) {
        regionData.push(data[i]);
      }
    }
    this.regionData = regionData;
    this.getClassify();
    this.getoutOfStock();
    //品牌
    brand_index({ page: 1, limit: 99999 }).then((res) => {
      this.brandData = res.list;
    });
  },
  computed: {
    secondClassify() {
      let _this = this;
      if (!_this.ajaxparams.classify_first_id) return [];

      let index = _this.classifyData.findIndex(
        (val) => _this.ajaxparams.classify_first_id == val.id
      );
      return _this.classifyData[index]["children"];
    },
    //商品特性,根据一级分类id 进行变化
    charactTags() {
      let res = [];
      if (this.form.classify_id && this.form.classify_id[2]) {
        this.characts.map((item) => {
          if (item.classify_id == this.form.classify_id[2]) {
            res = item.characts;
          }
        });
      }
      return res;
    },
  },
  methods: {
    //导出
    doExport() {
      goods_spec_list(this.ajaxparams).then(
        (res) => {
          this.$message.success("添加导出队列成功");
          this.$db.set("is_export", 1);
        },
        (err) => {
          this.$message.warning("导出失败");
        }
      );
    },
    //修改分类
    changeClassify() {
      this.form.charact_ids = "";
    },

    // 打开检测
    async openCheck(mobile) {
      let res = await UserGoodsWriteList_checkForbidden({
        mobile: mobile,
        goods_id: this.currentGoodsId,
      });
      this.checkItem = res;
      this.userMobile = mobile;
      this.checkModal = true;
    },

    // 用户名单操作
    addUser() {
      let mobile = this.userMobile;
      if (this.userDialogType === "forbidden") {
        this.addForbiddenUser(mobile);
      } else if (this.userDialogType === "userWrite") {
        this.addUserGoodWrite(mobile);
      }
    },
    delUser(id, index) {
      if (this.userDialogType === "forbidden") {
        this.delForbiddenUser(id, index);
      } else if (this.userDialogType === "userWrite") {
        this.delUserGoodWrite(id, index);
      }
    },
    //添加白名单用户
    addUserGoodWrite(mobile) {
      if (!mobile) {
        this.$message.warning("请输入手机号");
        return false;
      }
      this.forbiddenLoading = true;
      UserGoodsWriteList_add({
        mobile: mobile,
        goods_id: this.currentGoodsId,
      }).then(
        (res) => {
          this.$message.success("添加成功");
          this.mobile = "";
          this.forbiddenData.push({ id: res.id, name: res.name });
          this.forbiddenLoading = false;
          this.checkModal = false;
        },
        (err) => {
          console.log(err);
          this.forbiddenLoading = false;
          this.checkModal = false;
        }
      );
    },
    //删除白名单用户
    delUserGoodWrite(id, index) {
      console.log(id);
      UserGoodsWriteList_del({ id: id }).then((res) => {
        this.forbiddenData.splice(index, 1);
      });
    },
    //添加禁用用户
    addForbiddenUser(mobile) {
      if (!mobile) {
        this.$message.warning("请输入手机号");
        return false;
      }
      this.forbiddenLoading = true;
      forbidden_user_add({
        mobile: mobile,
        goods_id: this.currentGoodsId,
      }).then(
        (res) => {
          this.$message.success("添加成功");
          this.mobile = "";
          this.forbiddenData.push({ id: res.id, name: res.name });
          this.forbiddenLoading = false;
          this.checkModal = false;
        },
        (err) => {
          console.log(err);
          this.forbiddenLoading = false;
          this.checkModal = false;
        }
      );
    },
    //删除禁用用户
    delForbiddenUser(id, index) {
      console.log(id);

      forbidden_user_del({ id: id }).then((res) => {
        this.forbiddenData.splice(index, 1);
      });
    },
    checkUserName(value) {},
    getForbiddenUsers(goodsId) {},
    addForbiddenCity() {
      if (!this.forbiddenCity || this.forbiddenCity.length === 0) {
        this.$message.warning("请选择屏蔽城市");
        return false;
      }
      this.forbiddenLoading = true;
      const code =
        this.forbiddenCity[this.forbiddenCity.length - 1] ||
        this.forbiddenCity[this.forbiddenCity.length - 2] ||
        this.forbiddenCity[this.forbiddenCity.length - 3];
      if (this.forbiddenCityData.findIndex((i) => i.area_code == code) !== -1) {
        this.$message.warning("不能重复添加");
        return false;
      }
      const areaData = this.regionList.find((i) => i.code === code);
      const params = {
        area_code: areaData.code,
        area_name: areaData.name,
        goods_id: this.currentGoodsId,
      };
      forbidden_city_add(params).then(
        (res) => {
          this.$message.success("添加成功");
          this.mobile = "";
          this.forbiddenCityData.push({
            ...params,
            id: params.goods_id,
          });
          this.forbiddenLoading = false;
        },
        (err) => {
          console.log(err);
          this.forbiddenLoading = false;
        }
      );
    },
    // 商品禁用城市
    dialogFobidenCity(goodsId) {
      this.showForbiddenCity = true;
      this.currentGoodsId = goodsId;
      //重置 弹窗
      this.forbiddenCityData = [];
      // this.userName = ""
      forbidden_city_index({ goods_id: goodsId }).then((res) => {
        this.forbiddenCityData = res;
      });
    },

    //删除禁用城市
    delForbiddenCity(id, index) {
      console.log(id);

      forbidden_city_del({ id: id }).then((res) => {
        this.forbiddenCityData.splice(index, 1);
      });
    },
    filterProvince(inputValue, path) {
      return path.some((option) => option.name.indexOf(inputValue) > -1);
    },
    // 商品禁用用户
    dialogFobidenUser(goodsId) {
      this.userDialogType = "forbidden";
      this.showForbidden = true;
      this.currentGoodsId = goodsId;
      //重置 弹窗
      this.forbiddenData = [];
      this.userName = "";
      forbidden_user_index({ goods_id: goodsId }).then((res) => {
        this.forbiddenData = res;
      });
    },
    // 商品禁用用户
    dialogGoodsWriteUser(goodsId) {
      this.userDialogType = "userWrite";
      this.showForbidden = true;
      this.currentGoodsId = goodsId;
      //重置 弹窗
      this.forbiddenData = [];
      this.userName = "";
      UserGoodsWriteList_index({ goods_id: goodsId }).then((res) => {
        this.forbiddenData = res;
      });
    },
    // 取消禁用用户
    cancelForbidden() {
      console.log("cancelForbidden");
    },
    showImgPopup(img) {
      this.currentImg = IMGHOST + "/" + img;
      this.showImgStatus = true;
      console.log(this.currentImg);
    },
    closeImgPopup() {
      this.showImgStatus = false;
    },
    //添加商品标签
    addTag() {
      if (!this.form.tags) this.form.tags = [];
      if (!this.tagName || !this.tagColor || !this.tagBgColor) {
        this.$message.warning("请输入标签跟16进制颜色色值");
        return;
      }
      if (
        this.tagColor[0] !== "#" ||
        (this.tagColor.length !== 4 && this.tagColor.length !== 7) ||
        (this.tagBgColor.length !== 4 && this.tagBgColor.length !== 7)
      ) {
        this.$message.warning("16进制颜色色值有误");
        return;
      }
      let isDuplicate = this.form.tags.some((tag) => {
        if (tag.name === this.tagName) {
          return true;
        }
        return false;
      });
      if (isDuplicate) {
        this.$message.warning("标签 " + this.tagName + " 已经存在");
        return;
      }
      this.form.tags.push({
        name: this.tagName,
        color: this.tagColor,
        bgColor: this.tagBgColor,
      });
    },
    //删除商品标签
    delTag(index) {
      this.form.tags.splice(index, 1);
    },
    //添加 减少 商品特性标签
    toggleCharact(id) {
      const str = id + ",";
      if (this.form.charact_ids.indexOf(str) === -1) {
        //原来没有 添加进去
        this.form.charact_ids += str;
      } else {
        //原来有,删除掉
        this.form.charact_ids = this.form.charact_ids.replace(str, "");
      }
    },
    getoutOfStock() {
      //获取缺货商品列表
      goods_outOfStock().then((res) => {
        this.outOfStockData = res;
      });
    },
    province_code(codes) {
      codes = codes.split(",");
      let _this = this;
      let names = [];
      for (let i = 0; i < codes.length; i++) {
        let result = _this.regionData.find((item) => {
          return codes[i] == item.code;
        });
        if (result) {
          names.push(result.name);
        }
      }
      return names;
    },
    // 上传删除处理
    removeUpload(row) {
      if (row.type == "cover") {
        this.form.cover_img = "";
      } else if (row.type == "banner") {
        this.form.banner_imgs.splice(row.index, 1);
      } else if (row.type == "video") {
        this.form.video = "";
      } else if (row.type == "video_img") {
        this.form.video_img = "";
      } else {
        if (row.type.indexOf("spec_") != -1) {
          let index = row.type.split("_")[1];
          this.form.spec[index].img = "";
        }
      }
    },
    // 上传回调处理
    resultUpload(row) {
      if (row.type == "cover") {
        this.form.cover_img = row.url;
      } else if (row.type == "banner") {
        this.form.banner_imgs.push(row.url);
      } else if (row.type == "video") {
        this.form.video = row.url;
      } else if (row.type == "video_img") {
        this.form.video_img = row.url;
      } else {
        if (row.type.indexOf("spec_") != -1) {
          let index = row.type.split("_")[1];
          this.form.spec[index].img = row.url;
        }
      }
    },
    delSpec(index) {
      this.form.spec.splice(index, 1);
    },
    addSpec() {
      let spec_desc = [];
      for (let i = 0; i < this.specData.length; i++) {
        if (this.specData[i].val.length > 0) {
          spec_desc.push(this.specData[i].name + ":" + this.specData[i].val[0]);
        }
      }
      this.form.spec.push({
        price: 0,
        stock: 0,
        origin_price: 0,
        spec_desc: spec_desc,
        img: "",
      });
    },
    parametersChange(html) {
      this.form.parameters = html;
    },
    editorChange(html) {
      this.form.detail = html;
    },
    getSpec() {
      spec_index({ page: 1, limit: 999 }).then((res) => {
        let data = JSON.parse(JSON.stringify(res));
        for (let i = 0; i < data.length; i++) {
          data[i].val = data[i].val.split(",");
        }
        this.specDataAll = data;
        this.$nextTick(() => {
          this.getGoodsTypeSpec(this.form.goods_type);
        });
      });
    },
    // 获取对应规格
    getGoodsTypeSpec(val = 1) {
      this.specData = this.specDataAll.filter(
        (item) => item.goods_type === val
      );

      // 去重
      this.specData = this.uniqueArrayVal(this.specData);

      if (this.dialogType == "add") {
        this.$set(this.form, "spec", []);
      }
    },

    uniqueArrayVal(array) {
      return array.map((item) => {
        if (item.val && Array.isArray(item.val)) {
          return {
            ...item,
            val: [...new Set(item.val)],
          };
        }
        return item;
      });
    },

    getClassify() {
      classify_index({ page: 1, limit: 999 }).then((res) => {
        this.classifyData = res;
      });
    },
    async stockChange(e, row, index) {
      //编辑排序
      if (!e || e == 0) {
        this.$refs["stock" + row.id].resetEdit(row.stock);
        this.$message.warning("库存必须大于0");
        return;
      }
      goods_changeStock({ id: row.id, stock: e })
        .then(() => {
          this.$message.success("操作成功");
          this.isEditStock = true;
        })
        .catch(() => {
          this.$refs["stock" + row.id].resetEdit(row.stock);
        });
    },
    async onCellChange(e, row, index) {
      //编辑排序
      if (!e || e == 0) {
        this.$refs["edittableCell" + row.id].resetEdit(row.sort);
        this.$message.warning("排序值必须大于0");
        return;
      }
      goods_changeSort({ id: row.id, sort: e })
        .then(() => {
          this.$message.success("操作成功");
        })
        .catch(() => {
          this.$refs["edittableCell" + row.id].resetEdit(row.sort);
        });
    },
    //上下架
    async switchClick(val, index) {
      //上下架
      this.isDisabledSwitch = true;
      goods_changeShelves({ is_shelves: val.is_shelves ? 0 : 1, ids: val.id })
        .then((result) => {
          this.$message.success("操作成功");
          this.$refs.myTable.tableData[index].is_shelves = val.is_shelves
            ? 0
            : 1;
          setTimeout(() => {
            if (this.$refs.myTable.tableData[index].is_shelves) {
              this.getoutOfStock();
            }
            this.isDisabledSwitch = false;
          }, 1500);
        })
        .catch(() => {
          this.isDisabledSwitch = false;
        });
    },
    // 核心产品
    async switchImportant(val, index) {
      goods_changeImportant({
        is_important: val.is_important ? 0 : 1,
        ids: val.id,
      }).then((result) => {
        this.$message.success("操作成功");
        this.$refs.myTable.tableData[index].is_important = val.is_important
          ? 0
          : 1;
      });
    },
    onSelectChange(val) {
      console.log(val);
    },
    sBestSell(row) {
      this.bestSellForm = {
        id: row.id,
        is_best_sell: row.is_best_sell, //是否进入排行榜 1是,0否
        current_rank: row.current_rank ? row.current_rank : 10, //本期排名
        last_rank: row.last_rank ? row.last_rank : 10, //上期排名
      };
      this.showBestSell = true;
    },
    handleCancel(type) {
      this.submitLoading = false;
      this.dialogVisible = false;
      this.showBestSell = false;
      // if (this.dialogType == "add") {
      //   if (type == "add") {
      //     console.log('清空 addGoodsData')
      //     this.$db.del("addGoodsData")
      //   } else {
      //     this.$db.set("addGoodsData", this.form)
      //   }
      // }
      if (this.isEditStock) {
        this.isEditStock = false;
        this.$refs.myTable.getList();
      }
    },
    async bestSellSubmit() {
      this.submitLoading = true;
      let params = this.bestSellForm;
      goods_changeBestSell(params)
        .then((result) => {
          this.$message.success("操作成功");
          setTimeout(() => {
            this.$refs.myTable.getList();
            this.handleCancel();
          }, 1500);
        })
        .catch(() => {
          this.submitLoading = false;
        });
    },
    async onSubmit() {
      this.$refs.formModel.validate(async (valid) => {
        if (valid) {
          let params = JSON.parse(JSON.stringify(this.form));
          params.banner_imgs = this.form.banner_imgs.join(",");
          for (let i = 0; i < this.form.spec.length; i++) {
            params.spec[i].price = this.form.spec[i].price * 100;
            params.spec[i].origin_price = this.form.spec[i].origin_price * 100;
            params.spec[i].desc = this.form.spec[i].spec_desc.join(",");
          }
          if (params.classify_id.length === 0) {
            return this.$message.warning("请选择商品分类");
          }
          if (params.province_code) {
            params.province_code = params.province_code
              ? params.province_code.join(",")
              : "";
          }
          // if (params.forbidden_area) {
          //   params.forbidden_area = params.forbidden_area
          //     ? params.forbidden_area.join(",")
          //     : ""
          // }

          /*
          params.parameters = ""
          for (let i = 0; i < this.parameters.length; i++) {
            params.parameters +=
              this.parameters[i].title +
              "：" +
              this.parameters[i].sel +
              (i == this.parameters.length - 1 ? "" : "\n")
          }
          */
          this.submitLoading = true;
          if (this.dialogType == "add") {
            goods_add(params)
              .then((result) => {
                this.$message.success("操作成功");
                setTimeout(() => {
                  this.$refs.myTable.getList();
                  this.handleCancel("add");
                }, 1500);
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            goods_edit(params)
              .then((result) => {
                this.$message.success("操作成功");
                setTimeout(() => {
                  this.$refs.myTable.getList();
                  this.handleCancel();
                }, 1500);
              })
              .catch(() => {
                this.submitLoading = false;
              });
          }
        } else {
          return false;
        }
      });
    },
    async delConfirm(val) {
      //删除
      goods_del({ id: val.id }).then((result) => {
        this.$message.success("操作成功");
        setTimeout(() => {
          this.$refs.myTable.getList();
        }, 1500);
      });
      // let res = await this.$api.merchant_goods_del({id:val.id})
      // if(res.code==200){
      // 	this.$message.success('操作成功');
      // 	setTimeout(()=>{
      // 		this.$refs.myTable.getList()
      // 	},1500)
      // }
    },
    dialogClick(type, row) {
      let _this = this;
      this.dialogType = type;
      //获取商特性
      if (this.characts.length == 0) {
        charact_index().then((res) => {
          this.characts = res;
        });
      }

      if (type == "lack") {
      } else if (type == "edit") {
        // if (row.goods_type == 1) {
        //   let parameters = row.parameters.split("\n")
        //   for (let i = 0; i < parameters.length; i++) {
        //     let sel = parameters[i].split("：")
        //     this.parameters[i].sel = sel[1]
        //   }
        // }
        let classify_id = [
          row.classify_first_id,
          row.classify_second_id,
          row.classify_id,
        ];
        this.goods_price = row.goods_price;
        this.form = {
          id: row.id,
          goods_name: row.goods_name,
          brief: row.brief,
          parameters: row.parameters,
          banner_imgs: row.banner_imgs.split(","),
          cover_img: row.cover_img,
          is_recommend: row.is_recommend,
          is_single_spec: row.is_single_spec,
          video: row.video,
          classify_id: classify_id,
          attr_label_ids: row.attr_label_ids,
          sort: row.sort,
          brand_id: row.brand_id || 0,
          charact_ids: row.charact_ids || "",
          goods_type: row.goods_type,
          tags: !row.tags || row.tags === "null" ? [] : row.tags,
          detail: row.detail,
          area_limit: row.area_limit,
          spec: [],
          is_presale: row.is_presale ? row.is_presale : 0,
          presale_date: row.presale_date ? row.presale_date : "",
          share_img: row.share_img,
        };
        this.showShareImg = !!row.share_img;
        goods_detail({ id: row.id }).then((obj) => {
          let specList = [];
          if (obj.province_code) {
            this.$set(
              this.form,
              "province_code",
              obj.province_code ? obj.province_code.split(",").map(Number) : []
            );
          }
          // if (obj.forbidden_area) {
          //   this.$set(
          //     this.form,
          //     "forbidden_area",
          //     obj.forbidden_area ? obj.forbidden_area.split(",").map(Number) : []
          //   )
          // }

          this.form.video_img = obj.video_img;
          this.form.presale_date = obj.presale_date
            ? obj.presale_date
            : this.form.presale_date;
          for (let i = 0; i < (obj.spec ? obj.spec.length : 0); i++) {
            let spec = obj.spec[i].desc.split(",");
            let arr = {
              id: obj.spec[i].id,
              price: obj.spec[i].price / 100,
              origin_price: obj.spec[i].origin_price / 100,
              stock: obj.spec[i].stock,
              num: obj.spec[i].num,
              spec_desc: spec,
              img: obj.spec[i].img,
            };
            specList.push(arr);
          }
          this.form.spec = specList;
        });
      } else {
        // add
        // this.form = {
        // }
      }
      this.getSpec();
      this.dialogVisible = true;
    },
    // 生成分享图片
    saveImg() {
      this.showShareImg = true;
      setTimeout(() => {
        html2canvas(this.$refs.shareImg, {
          useCORS: true,
          backgroundColor: null,
          scale: 2,
          dpi: 300,
        }).then((canvas) => {
          const dataUrl = canvas.toDataURL("images/jpg");
          this.customRequest(dataUrl);
        });
      }, 500);
    },
    uploadFile(dataUrl) {
      let _this = this;
      let ossRes = _this.$db.get("ossRes");
      var formData = new FormData();
      //注意formData里append添加的键的大小写
      let keyValue = ossRes.dir + new Date().getTime() + ".jpg";
      formData.append("key", keyValue); //存储在oss的文件路径
      formData.append("OSSAccessKeyId", ossRes.accessId); //accessKeyId
      formData.append("policy", ossRes.policy); //policy
      formData.append("signature", ossRes.signature); //签名
      var blob = this.dataURItoBlob(dataUrl);
      formData.append("file", blob, Date.now() + ".jpg");
      formData.append("success_action_status", 201); //成功后返回的操作码
      axios
        .post(ossRes.host, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.status == 201) {
            //上传成功 上传的路径就是要使用的路径
            _this.form.share_img = "/" + keyValue;
          } else {
            console.log("上传失败");
          }
        })
        .catch((error) => {
          console.log("上传失败", error);
        });
    },
    async customRequest(dataUrl) {
      this.loading = true;
      let _this = this;
      let getOssToken = _this.$db.get("ossRes");
      if (getOssToken && getOssToken.expire * 1000 > new Date().getTime()) {
        this.uploadFile(dataUrl);
      } else {
        uploader()
          .then((res) => {
            _this.$db.set("ossRes", res);
            this.uploadFile(dataUrl);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    dataURItoBlob(dataURI) {
      // base64转buffer
      var byteString = atob(dataURI.split(",")[1]);
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
  },
};
</script>
