<style scoped>
  .img {
    height: 30px;
  }

  .ellipsis-1 {
    max-width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    word-break: break-all;
  }

  .w150 {
    width: 150px;
  }
</style>
<template>
  <div class="pageCentent">
    <!-- 搜索条件 -->
    <a-form-model size="small" layout="inline" :model="ajaxparams" @keyup.native.enter="submitForm()">
      <a-form-model-item label="用户名">
        <a-input  placeholder="请输入用户名" v-model="formModel['2_name']" />
      </a-form-model-item>
      <a-form-model-item label="业务员">
        <a-input  placeholder="请输入业务员名称" v-model="formModel['manager_name_no']" />
      </a-form-model-item>
      <a-form-model-item label="等级">
        <a-select allowClear class="w150" v-model="formModel['2_level']" placeholder="请选择等级"  :showSearch="true" optionFilterProp="label">
          <a-select-option :label="item.vip" :value="item.key" v-for="(item, index) in levels" :key="index">{{ item.vip }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="共富币">
        <a-select allowClear class="w150" v-model="formModel['is_coin']" placeholder="请选择等级"  :showSearch="true" optionFilterProp="label">
          <a-select-option label="是" value="1">是</a-select-option>
          <a-select-option label="否" value="0">否</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="类型">
        <a-select allowClear class="w150" v-model="formModel['bill_type']" placeholder="请选择类型"  :showSearch="true" optionFilterProp="label">
          <a-select-option label="赠送购物金" value="benefits_reward_amt">赠送购物金</a-select-option>
          <a-select-option label="后台充值" value="merchant_edit">后台修改</a-select-option>
           <a-select-option label="共富奖励" value="strategic_award">共富奖励</a-select-option>
          <a-select-option label="活动奖励" value="activity">活动奖励</a-select-option>
          <a-select-option label="购货奖励" value="purchase_award">购货奖励</a-select-option>
          <a-select-option label="推荐奖励" value="rec_award">推荐奖励</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="省份">
        <a-select style="width:200px" allowClear v-model="formModel['2_province_code']" placeholder="请选择省份" :showSearch="true" optionFilterProp="label">
          <a-select-option :label="item.name" :value="item.code" v-for="(item, index) in provinceData" :key="index">{{ item.name
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="日期">
        <a-range-picker v-model="formModel['1_create_at_between']" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" value=null />
      </a-form-model-item>
      <a-form-model-item>
        <a-button icon="search" @click="submitForm()" type="primary">搜索</a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click="doExport" type="primary">导出</a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <MyTable :isSelection="false" class="mt10" :apiUrl="apiUrl" :params="ajaxparams" ref="myTable" :columns="columns">
 
        <a-space slot="value" slot-scope="item,row,index">
          <span>{{ (item / 100).toFixed(2) }}</span>
        </a-space>
        <a-space slot="is_coin" slot-scope="item,row,index">
          <span>{{ item ? "是" : "否"}}</span>
        </a-space>

        <a-space slot="manager_id" slot-scope="item, row, index">
          <div>{{ findManager(item) }}</div>
        </a-space>


        <a-space slot="province_code" slot-scope="item,row,index">
          <span>{{ findProvinceName(item) }}</span>
        </a-space>
  
      </MyTable>
    </div>
  </div>
</template>
<script>
import MyTable from "@/components/myTable.vue"
import {
  user_bill_index,
} from "@/api/user_bill"
import { manager_user_index } from "@/api/manager"
import moment from "moment"
import { export_user_bill }from "@/api/export"

export default {
  components: { MyTable },
  data() {
    return {
      apiUrl: user_bill_index,
      ajaxparams: {
        "recharge_no":1
      },
      formModel:{},
      levels: [
        { vip: "vip", key: "vip", color: "#87d068" },
        { vip: "A1", key: "A1", color: "#2db7f5" },
        { vip: "A2", key: "A2", color: "#ff4500" },
        { vip: "A3", key: "A3", color: "#f50" },
      ],

      columns: [
        {
          title: "id",
          ellipsis: true,
          dataIndex: "id",
          key: "id",
        },
        {
          title: "用户名",
          ellipsis: true,
          width:120,
          dataIndex: "name",
          key: "name",
        },
        
        {
          title: "等级",
          width:80,
          dataIndex: "level",
          key: "level",
          scopedSlots: { customRender: "level" },
        },
         {
          title: "省份",
          width:80,
          dataIndex: "province_code",
          key: "province_code",
          scopedSlots: { customRender: "province_code" },
        },
        {
          title: "金额",
          ellipsis: true,
          dataIndex: "value",
          key: "value",
          scopedSlots: { customRender: "value" },
        },
        {
          title: "是否共富币",
          dataIndex: "is_coin",
          width:80,
          key: "is_coin",
          scopedSlots: { customRender: "is_coin" },
        },
        {
          title: "业务员",
          dataIndex: "manager_id",
          key: "manager_id",
          scopedSlots: { customRender: "manager_id" },
        },

        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
          key: "remark",
        },
        {
          title: "日期",
          dataIndex: "create_at",
          key: "create_at",
        },
      ],
      regionData: [],
	  provinceData:[],
    managerUser:[],
    }
  },
  computed: {
   
  },
  created() {
    this.getManagerUser()
  },
  mounted() {
    this.regionData = this.$db.get("regionList").data
    this.provinceData = this.regionData.filter(item => {
      return item.depth == 1
    })

	console.log(this.provinceData)
  },
  methods: {
    //导出
    doExport(){
      this.setAjaxparams()
      export_user_bill(this.ajaxparams).then(res => {
        this.$message.success("添加导出队列成功")
        this.$db.set('is_export',1)
      },err => {
        this.$message.warning("导出失败")
      })
    },
    // 查找业务员
    findManager(manager_id) {
      if (!manager_id) return "";
      const targetManager = this.managerUser.filter((obj) => obj.id == manager_id);
      return targetManager[0].manager_name;
    },
    findProvinceName(code){
			let rest = this.regionData.find((item)=>{return code==item.code})
			if(rest){
				return rest.name
			}
			return ''
		},
    async getManagerUser() {
      let res = await manager_user_index();
      console.log('sofiosdfios')
      console.log(res)
      this.managerUser = res;
    },
    submitForm(){
      this.setAjaxparams()
      this.$nextTick(() => {
        this.$refs.myTable.search()
      })
      
    },
    //设置提交属性
    setAjaxparams(){
      this.ajaxparams = {"recharge_no":1}
      for (let k in this.formModel){
          if(k === '1_value' && this.formModel[k])this.ajaxparams[k] = this.formModel[k] * 100
          else if(k === '2_level' && this.formModel[k]  === 'A4') this.ajaxparams["is_salesman"] = 1
          else this.ajaxparams[k] = this.formModel[k]
      }
    }
  },
}
</script>
	